<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" width="80" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" label="名称" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column prop="award_scale" :formatter="e=>e.award_scale + '%'" label="分佣比例" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column label="升级条件" :formatter="upFormatter"></el-table-column>
      <el-table-column label="操作" fixed="right" width="200" show-overflow-tooltip>
        <template v-slot:header>
          <el-button @click="create" type="primary">添加等级</el-button>
        </template>
        <template v-slot="s">
          <el-button @click="editDose(s.row)" type="primary">编辑</el-button>
          <el-button @click="del(s.row)" type="danger">删除</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
    <el-dialog title="编辑等级" :visible.sync="editBox" @close="editBoxClose" width="30rem">
      <div>
        <el-form label-width="70px">
          <el-form-item label="等级名称">
            <el-input v-model="editForm.title"></el-input>
          </el-form-item>
          <el-form-item label="佣金比例">
            <el-input-number v-model="editForm.award_scale"></el-input-number>%
          </el-form-item>
          <el-divider>升级条件</el-divider>
          <el-form-item label="直推">
            <el-input-number v-model="editForm.up_boss_num"></el-input-number>个老板
          </el-form-item>
          <el-form-item v-if="(editForm.up_target_level_id !== 0 && editForm.id > 0) || !editForm.id" label="直推">
            <el-input-number v-model="editForm.up_target_num"></el-input-number>个
            {{editForm.id > 0 ? editForm.up_target_level.title : last.title}}
          </el-form-item>
          <el-form-item label="关系链含">
            <el-input-number v-model="editForm.up_has_agent_num"></el-input-number>个代理
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">提交</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>

export default {
  components:{},
  name: "level",
  data(){
    return{
      list:[],
      page:1,
      total:0,
      editBox:false,
      editForm:{
        title:"",
        up_boss_num:0,
        up_target_num:0,
        up_target_level_id:0,
        up_has_agent_num:0,
        award_scale:0,
      },
      last:{
        title:"",
      },
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    upFormatter(e){
      let str = `直推${e.up_boss_num}个老板；`;
      if (e.up_target_level_id > 0)str += `直推${e.up_target_num}个${e.up_target_level?.title}；`;
      str += `邀请关系链下含${e.up_has_agent_num}个代理及代理以上级别用户；`
      return str;
    },
    del({id}){
      this.$u.api.shop.plugin.teamManage.levelDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    editDose(item){
      this.editForm = {...item};
      this.editBox = true;
    },
    editBoxClose(){
      this.editForm = {
        title:"",
        up_boss_num:0,
        up_target_num:0,
        up_target_level_id:0,
        up_has_agent_num:0,
        award_scale:0,
      }
    },
    create(){
      this.editForm.up_target_level_id = this.last.id;
      this.editBox=true
    },
    editSubmit(){
      this.$u.api.shop.plugin.teamManage.levelEdit(this.editForm).then(()=>{
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.teamManage.levelAll().then(res=>{
        this.list = res;
        this.last = res[res.length-1]
      })
    },
  },
}
</script>

<style scoped>

</style>